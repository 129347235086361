/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import CollectionFilterBarFilter from './Filter'

const CollectionFilterBarFilters = ({
  menuOpen = false,
  options,
  onClick,
  currentCollection,
  sx,
  ...props
}) => {
  return (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: ['row'],
        flexWrap: ['wrap', 'unset'],
        gap: ['.5em', '1em'],
        justifyContent: 'center',
        position: ['unset', 'unset', 'absolute'],
        top: '150%',
        paddingBottom: ['3em', '0'],
        ...sx
      }}
      {...props}
    >
      {options.map(({ title, slug }) => (
        <CollectionFilterBarFilter
          key={slug}
          slug={slug}
          currentCollection={currentCollection}
          title={title}
          onClick={onClick(slug)}
        />
      ))}
    </Flex>
  )
}

CollectionFilterBarFilters.propTypes = {
  menuOpen: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  currentCollection: PropTypes.shape({
    slug: PropTypes.string
  })
}

export default CollectionFilterBarFilters
