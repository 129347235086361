import theme from '~/gatsby-plugin-theme-ui'

const {
  colors: {
    modes: { CDP: collectionColors },
  },
} = theme

export const getBackgroundColor = (currentCollection, slug) => {
  if (
    collectionColors.hasOwnProperty(slug) &&
    currentCollection?.slug !== slug
  ) {
    return collectionColors[slug]
  }

  if (currentCollection?.slug === slug || !currentCollection) {
    return collectionColors.transparent
  }

  return collectionColors.defaultBackground
}

export const getBorderColor = slug => {
  if (collectionColors.hasOwnProperty(slug)) {
    return collectionColors[slug]
  }

  return collectionColors.defaultBackground
}

export const getTextColor = (currentCollection, slug) => {
  if (
    currentCollection?.slug === slug &&
    collectionColors.hasOwnProperty(slug)
  ) {
    return collectionColors[slug]
  }

  if (!currentCollection && !slug) {
    return collectionColors.defaultBackground
  }

  return collectionColors.defaultText
}
