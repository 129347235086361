/** @jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Card, Flex, jsx } from 'theme-ui'
import ImgLoader from '~/components/Generic/ImgLoader'
import { useLocation } from '@reach/router'
import { toURL } from '~/utils/intl/localePrefix'

const KitCard = ({ kit }) => {
  const { trackClickProduct } = useAnalytics()
  const location = useLocation()
  const { name, slug, mainImage } = kit

  const url = toURL(location && location.pathname, `/bundles/${slug}/`)

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Link
          to={url}
          onClick={() => trackClickProduct(slug)}
          sx={{
            '&:hover': {
              // '.gatsby-image-wrapper': {
              //   overflow: 'visible !important'
              // },
              img: {
                transformOrigin: 'center',
                transform: 'scale(1.1) translateY(-0%)',
              },
            },
          }}
        >
          <ImgLoader
            image={mainImage}
            slug={slug}
            forwardSx={{
              borderRadius: '50%',
              aspectRatio: '1',
              border: '2px solid var(--theme-ui-colors-mac-secondary)',
            }}
          />
        </Link>
        <Flex
          pt={['0.5rem', '0.5rem', '1.25rem']}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <Link
            sx={{
              alignItems: 'center',
              bottom: '20%',
              background: '#007300',
              border: '2px solid var(--theme-ui-colors-secondary)',
              borderRadius: '50%',
              color: 'var(--theme-ui-colors-secondary)',
              display: 'flex',
              justifyContent: 'center',
              textTransform: 'none',
              fontFamily: 'Sofia',
              position: 'absolute',
              right: '0',
              width: '13em',
              height: '6em',
            }}
            to={url}
          >
            {name}
          </Link>
        </Flex>
      </Flex>
      <Box>
        {/* {hasVariants ? (
        <ChooseKitButton slug={slug}  sx={{ width: '100%', margin: '20px 0' }} />
      ) : (
        <ProductAddToCartButton
          soldOut={soldOut}
          comingSoon={comingSoon}
          price={price}
          quantity={1}
          regularPrice={regularPrice}
          sku={products
            .map(p => p.variants.map(v => v.sku))
            .sort()
            .join('|')}
          sx={{ width: '100%', margin: '20px 0' }}
        />
      )} */}
        {/* <Link
            sx={{
              display: 'block',
              textTransform: 'none',
              textAlign: 'center',
              textTransform: 'Uppercase',
              textDecoration: 'underline',
              textUnderlineOffset: '5px',
              textDecorationThickness: '3px',
              fontFamily: 'Marby',
              letterSpacing: '0.5px',
              color: 'var(--theme-ui-colors-tertiary)'
            }}
            to={url}
          >
            {'View Details'}
          </Link> */}
        <Link
          // sx={{
          //   display: 'block',
          //   textTransform: 'none',
          //   textAlign: 'center',
          //   textTransform: 'Uppercase',
          //   textDecoration: 'underline',
          //   textUnderlineOffset: '5px',
          //   textDecorationThickness: '3px',
          //   fontFamily: 'Marby',
          //   letterSpacing: '0.5px',
          //   color: 'var(--theme-ui-colors-mac-primary)'
          // }}
          sx={{
            alignItems: 'center',
            borderRadius: '8px',
            color: 'var(--theme-ui-colors-mac-secondary)',
            justifyContent: 'center',
            display: 'flex',
            fontSize: ['18px', '20px'],
            width: '70%',
            height: '50px',
            margin: '20px auto',
            fontFamily: 'Sofia',

            background: '#007300',
            '&:hover': {
              background: '#AEE0FD',
            },
          }}
          to={url}
        >
          {'Shop Now'}
        </Link>
      </Box>
    </Card>
  )
}

KitCard.propTypes = {
  kit: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    soldOut: PropTypes.bool,
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
}

export default KitCard
