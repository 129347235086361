import React, { useState, useEffect } from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { StringParam, useQueryParam } from 'use-query-params'
import CollectionFilterBar from '~/components/Collection/FilterBar'
import CollectionList from '~/components/Collection/List'
import { Box, Heading, useColorMode } from 'theme-ui'
import { GenericSectionDivider } from '~/components/Generic/SectionDivider'

const ProductListPage = ({ catalog }) => {
  const [currentCollectionParam, setCurrentCollectionParam] = useQueryParam(
    'collection',
    StringParam,
  )

  const [currentCollectionSlug, setCurrentCollectionSlug] = useState(
    currentCollectionParam,
  )

  const [colorMode, setColorMode] = useColorMode('PCP')

  // if the collection in the URL changes via external means (e.g. a footer link was clicked), update the component
  useEffect(() => {
    setCurrentCollectionSlug(currentCollectionParam || null)
    setColorMode('PCP')
  }, [currentCollectionParam])

  const onFilter = slug => {
    // setCurrentCollectionParam updates the URL but does not update currentCollectionParam
    setCurrentCollectionParam(slug || undefined, 'replaceIn')
    setCurrentCollectionSlug(slug)
  }

  const currentCollection = currentCollectionSlug
    ? catalog.collections.find(({ slug }) => slug === currentCollectionSlug)
    : null

  const collectionsToShow = currentCollection
    ? [currentCollection]
    : catalog.collections

  return (
    <Box
      sx={{ background: 'var(--theme-ui-colors-accent)', overflow: 'hidden' }}
    >
      <Box sx={{ background: 'var(--theme-ui-colors-primary)' }}>
        <Heading
          variant="text.h1"
          sx={{
            color: 'var(--theme-ui-colors-accent)',
            padding: '1em 0',
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {currentCollection ? `${currentCollection.pcpHeader}` : `Shop all`}
        </Heading>
      </Box>
      <GenericSectionDivider
        sx={{
          fill: 'primary',
          stroke: 'primary',
        }}
      />

      <CollectionFilterBar
        collections={catalog.collections}
        currentCollection={currentCollection}
        onChange={onFilter}
      />

      {collectionsToShow.map(collection => (
        <CollectionList key={collection.slug} collection={collection} />
      ))}
    </Box>
  )
}

export default injectIntl(ProductListPage)
