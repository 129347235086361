/** @jsx jsx */

import PropTypes from 'prop-types'

import { Box, Card, Flex, jsx } from 'theme-ui'
import ImgLoader from '~/components/Generic/ImgLoader'
import ProductLink from '~/components/Product/Link'
import StarRating from '../StarRating'

const ProductCard = ({ product }) => {
  const { mainImage, name, slug } = product

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <ProductLink
          slug={slug}
          sx={{
            '&:hover': {
              img: {
                transformOrigin: 'center',
                transform: 'scale(1.1) translateY(-0%)',
              },
            },
          }}
        >
          <ImgLoader
            image={mainImage}
            slug={product.slug}
            forwardSx={{
              borderRadius: '50%',
              aspectRatio: '1',
              border: '2px solid var(--theme-ui-colors-mac-secondary)',
            }}
          />
        </ProductLink>

        <Flex
          pt={['0.5rem', '0.5rem', '1.25rem']}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <ProductLink
            sx={{
              alignItems: 'center',
              bottom: ['35%', null, '27%'],
              background: 'var(--theme-ui-colors-mac-primary)',
              border: '2px solid var(--theme-ui-colors-mac-secondary)',
              borderRadius: '50%',
              color: 'var(--theme-ui-colors-mac-secondary)',
              display: 'flex',
              justifyContent: 'center',
              textTransform: 'none',
              fontFamily: 'Sofia',
              position: 'absolute',
              fontSize: ['14px', null, '20px'],
              textAlign: 'center',
              right: '0',
              width: ['127px', null, '222px'],
              minHeight: ['58px', null, '103px'],
              py: '15px',
              px: '5px',
            }}
            slug={slug}
          >
            {name}
          </ProductLink>
        </Flex>
        <StarRating
          product={product}
          forwardSx={{ alignSelf: 'center', color: 'governorBay', pt: '20px' }}
        />
      </Flex>
      <Box>
        <ProductLink
          sx={{
            alignItems: 'center',
            borderRadius: '8px',
            color: 'var(--theme-ui-colors-mac-secondary)',
            justifyContent: 'center',
            display: 'flex',
            fontSize: ['18px', '20px'],
            width: '70%',
            height: '50px',
            margin: '20px auto',
            fontFamily: 'Sofia',
            background: 'var(--theme-ui-colors-mac-primary)',
            '&:hover': {
              background: '#AEE0FD',
            },
          }}
          slug={slug}
        >
          {'Shop Now'}
        </ProductLink>
      </Box>
    </Card>
  )
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    comingSoon: PropTypes.bool,
    soldOut: PropTypes.bool,
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    size: PropTypes.string,
    sku: PropTypes.string,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
}

export default ProductCard
