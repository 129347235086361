/** @jsx jsx */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Flex, jsx } from 'theme-ui'
import CollectionFilterBarFilters from './Filters'
import { useTikTokHelper } from '~/components/TikTokHelper'

const CollectionFilterBar = ({
  collections,
  currentCollection,
  onChange,
  sx,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const { viewContent } = useTikTokHelper()

  useEffect(() => {
    if (!currentCollection) return
    viewContent({
      description: currentCollection.title,
      contentId: currentCollection.slug,
    })
  }, [currentCollection])

  const onFilterClick = slug => () => {
    setMenuOpen(false)
    onChange(slug)
  }

  const options = collections.concat([
    {
      title: 'Shop All',
      slug: null,
    },
  ])

  return (
    <Container
      sx={{
        padding: [0, '0 1rem', '0 4rem'],
        maxWidth: '1440px',
        ...sx,
      }}
      {...props}
    >
      <Flex
        sx={{
          width: '100%',
          justifyContent: ['cetner', 'center', 'end'],
          position: 'relative',
          alignItems: 'center',
          height: ['100%', '150px', '50px'],
        }}
        variant="text.link"
      >
        {/* <CollectionFilterBarTitle
          onClick={onTitleClick}
          currentCollection={currentCollection}
          menuOpen={menuOpen}
        /> */}

        {/* <CollectionFilterBarTitleDesktop /> */}

        <CollectionFilterBarFilters
          menuOpen={menuOpen}
          options={options}
          onClick={onFilterClick}
          currentCollection={currentCollection}
        />

        {/* <CollectionFilterBarProductCount
          collections={collections}
          currentCollection={currentCollection}
        /> */}
      </Flex>
    </Container>
  )
}

CollectionFilterBar.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.shape,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          sku: PropTypes.string,
          slug: PropTypes.string,
          name: PropTypes.string,
          description: PropTypes.string,
          price: PropTypes.number,
          mainImage: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          }),
          images: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            }),
          ),
          comingSoon: PropTypes.bool,
        }),
      ),
    }),
  ),
  currentCollection: PropTypes.shape({}),
  onChange: PropTypes.func,
}

export default CollectionFilterBar
