/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx, useThemeUI } from 'theme-ui'
import {
  getBackgroundColor,
  getBorderColor,
  getTextColor,
} from '~/utils/get-collection-colors'

const CollectionFilterBarFilter = ({
  title,
  slug,
  onClick,
  currentCollection,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: getBackgroundColor(currentCollection, slug),
        border: ['3px solid'],
        borderRadius: '5em',
        borderColor: getBorderColor(slug),
        color: getTextColor(currentCollection, slug),
        cursor: 'pointer',
        textAlign: 'center',
        minWidth: '150px',
        width: ['40%', 150],
        height: ['34px', '45px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: ['0', '0'],
      }}
      variant="text.eyebrow.uppercase"
    >
      {title}
    </Box>
  )
}

CollectionFilterBarFilter.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  onClick: PropTypes.func,
  currentCollection: PropTypes.shape({
    slug: PropTypes.string,
  }),
}

export default CollectionFilterBarFilter
