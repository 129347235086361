/** @jsx jsx */
import PropTypes from 'prop-types'
import { Container, Flex, Heading, jsx, useThemeUI } from 'theme-ui'
import KitList from '~/components/Kit/List'
import ProductList from '~/components/Product/List'
import { getTextColor } from '~/utils/get-collection-colors'

const CollectionList = ({ collection }) => {
  const { title, products, kits } = collection

  return (
    <Container
      sx={{
        maxWidth: '1440px',
        paddingTop: ['12px', '0', null],
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
          padding: ['16px 24px', null, '32px 0'],
          width: '100%',
        }}
      >
        <Heading
          variant="text.h2"
          sx={{
            lineHeight: '1em',
            paddingRight: [null, '5rem'],
            textAlign: ['center', 'left'],
            textTransform: 'uppercase',
            fontSize: '2em',
            marginBottom: ['8px', 0, null],
            color: getTextColor(collection, collection.slug),
          }}
        >
          {title}
        </Heading>
      </Flex>
      {products && <ProductList products={products.filter(p => !p.hidden)} />}
      {kits && <KitList kits={kits} />}
    </Container>
  )
}

CollectionList.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({}),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
        mainImage: PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
        comingSoon: PropTypes.bool,
      }),
    ),
    kits: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        price: PropTypes.number,
        regularPrice: PropTypes.number,
        products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        soldOut: PropTypes.bool,
        comingSoon: PropTypes.bool,
        description: PropTypes.shape({}),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
      }),
    ),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
}

export default CollectionList
